import React, { Component } from 'react';
import { CertificationConsumer } from '../../contexts/certification';
import {post, setApiResultAgreement} from '../../global/FUNC';
import URI from '../../global/URI';

class Cert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tr_cert : '',
      tr_url : '',
      formUrl : '',
    }
  }
  /** 본인인증 팝업창 띄어주는 부분 */
  onOpenKMCCertificationPopup = () => {
    const certForm = document.reqKMCISForm;
    const pop = window.open('','certForm','height=500,width=400');

    certForm.target = "certForm";
    certForm.submit();
    pop.window.focus();

  }


  /** 본인인증 버튼 눌렀을 때 본인인증 페이지를 띄어주기 위한 변수를 저장하는 부누 */
  onClickCertificationBtn= () => {
    const url = URI.BASE_URL + URI.uriCert;
    const data = {
      sign_url:this.props.value
    };

    post(url, data).then((result)=>{
        const data = result.data;
        this.setState({
            tr_cert : data.kmcRequestParam.tr_cert,
            tr_url : data.kmcRequestParam.tr_url,
            formUrl : data.kmcUrl+URI.uriKmc
        },()=>this.onOpenKMCCertificationPopup());
    });
  }

  componentWillUnmount =() => {
    window.removeEventListener("message",(e)=>{
      setApiResultAgreement(this.props, e.data);
    });
  }

  componentDidMount =() => {
    window.addEventListener("message",(e)=>{
      setApiResultAgreement(this.props, e.data);
    });
    this.onClickCertificationBtn();
  }

  render() {
    return (
      <form name="reqKMCISForm" method="post" action={this.state.formUrl}>
          <input type="hidden" name="tr_cert" value={this.state.tr_cert}/>
          <input type="hidden" name="tr_url" value={this.state.tr_url}/>
      </form>
    );
  }
}

const SendsContainer = (props) => (
  <CertificationConsumer>
        {
          ({state}) => (
            <Cert 
                  props = {props.props}
                  value={state}
            />
          )
        }
  </CertificationConsumer>
)
export default SendsContainer;