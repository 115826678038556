
import React , {useState, useEffect} from 'react';
import CONST from '../../global/CONST';
import Autocomplete from 'react-autocomplete';
import { LoginConsumer } from '../../contexts/login';
import './home.css';



const LoginForm = (TargetList) => {
    const [inputStoreNm, setInputStoreNm] = useState("");
    const [name, setName] = useState("");
    const [phoneNm, setPhoneNm] = useState("");
   
    let inputPhone = TargetList.value.inputPhone; 
    let inputStore = TargetList.value.inputStore; 
    let inputOwner = TargetList.value.inputOwner;       // 전화번호 ref 사용을 위한 변수 선언

    /** 전화번호 입력 변화를 감지하는 이벤트로 숫자만 입력받는다. */
    function onChangePhoneNumber(e) {
      const regexp = /^[0-9]*$/;

      if(regexp.test(e.target.value)){
          setPhoneNm(e.target.value);
      }
    }

    /** 전화번호 input focus out 이벤트로 양식에 맞는지 안맞는지 판단. */
    function   onCheckPhoneValidate() {
        const re = /^\d{3}\d{3,4}\d{4}$/;
        if(phoneNm.trim().length > CONST.ZERO){
          if(re.test(phoneNm)){
            TargetList.setValue("isPhoneValidate", true);
            TargetList.setValue("phoneNo", phoneNm);
          }else{
            alert("휴대폰 번호 양식에 맞지 않습니다.");
            setPhoneNm("");
            TargetList.setValue("isPhoneValidate", false);
            inputPhone.focus();
          }
        }else{
          TargetList.setValue("isPhoneValidate", false);
        }
        
    }

    useEffect(() => {    
        TargetList.setValue("inputStore", inputStore);
        TargetList.setValue("inputPhone", inputPhone);
        TargetList.setValue("inputOwner", inputOwner);
    });

    return (
      <div>
           {/* 매장명 */}
          <div className="rowInfo">
            <div className="landingLable">매장명</div>
            <div className="landinginfo">
                  <Autocomplete
                          menuStyle={{ position: 'absolute',top:'auto', maxHeight:'40%', overflow:'scroll'}}
                          ref={(input) => { inputStore = input; }} 
                          inputProps={{ style: {  width: '70%',height: '30px',fontSize:15, paddingLeft:'7px'}, 
                                        placeholder:'매장을 선택해주세요'}}
                          wrapperStyle={{width:'100%'}}
                          getItemValue={(item)=>item.tatStoreNm}
                          items={TargetList.props.targetItemList}
                          shouldItemRender={(state, value)=>{
                              return state.tatStoreNm.indexOf(value) > -1
                          }}
                          renderItem={(item, isHighlighted) =>
                              <div key={item.tatStoreNo} style={{ background: isHighlighted ? 'lightgray' : 'white', height:"30px",fontSize:15, paddingTop:5, paddingLeft:5 ,}}>
                                  {item.tatStoreNm}
                              </div>
                          }
                          value={inputStoreNm}
                          onChange={(e) => {
                            setInputStoreNm(e.target.value);
                            TargetList.setValue("isSelectStore",false);
                          }}
                          onSelect={(val) => {
                            TargetList.setValue("isSelectStore",true);
                             setInputStoreNm(val);
                             TargetList.setValue("storeNm", val);
                          }}
                />
            </div>
          </div>

          {/* 점주명 */}
          <div className="rowInfo">
            <div className="landingLable">점주명</div>
            <div className="landinginfo">
                <input 
                        ref={(input) => { inputOwner = input; }} 
                        type="text" 
                        className="loginInput" 
                        placeholder="공백없이 입력해주세요." 
                        onChange={(e)=>{
                          setName(e.target.value.trim());
                          TargetList.setValue("ownerName", e.target.value.trim());
                        }}
                        value={name}
                />
            </div>
        </div>   

        {/* 휴대폰번호 */}
        <div className="rowInfo">
            <div className="landingLable">휴대폰</div>
            <div className="landinginfo">
                <input 
                       type="text" 
                       ref={(input) => { inputPhone = input; }} 
                       onBlur={onCheckPhoneValidate}
                       className="loginInput" 
                       maxLength={11}
                       onChange={onChangePhoneNumber}
                       value={phoneNm}
                       placeholder="'-' 빼고 숫자만 입력해주세요." 
                />
            </div>
        </div> 
        
      </div>
      
    );

};
const SendsContainer = (props) => (
  <LoginConsumer>
        {
          ({state, actions}) => (
            <LoginForm 
                  props={props}
                  value={state}
                  setValue={actions.setValue}
            />
          )
        }
  </LoginConsumer>
)
export default SendsContainer;

