
import React from 'react';
import { CertificationConsumer } from '../../../contexts/certification';
import '../answer.css';


const TextCheckbox = (CheckBox) => {
    function onChangeCheckBox(){
        const isCheckedv = CheckBox.value.subContent;
        CheckBox.setValue("subContent", !isCheckedv);
    }


    return (
        <div>
            <div className="answerBody" style={{width:CheckBox.data.width,height:(CheckBox.data.height)*0.5}}>
                <div className="answerBodyText">
                    {(CheckBox.data.text).replace(/(<br>|<br\/>|<br \/>)/g, '\r\n')}
                </div>
                
             
            </div>

            <div className="bodyCheckbox">
                <input type="checkbox" id="answerCheckbodx" onChange={onChangeCheckBox} checked={CheckBox.value.subContent} 
                       value={CheckBox.value.subContent}/>
                <label htmlFor="answerCheckbodx" className="checkboxText">위 내용에 동의합니다.</label>
            </div>
        </div>
    );

};
const SendsContainer = (props) => (
    <CertificationConsumer>
          {
            ({state, actions}) => (
              <TextCheckbox
                    data={props}
                    value={state}
                    setValue={actions.setValue}
              />
            )
          }
    </CertificationConsumer>
  )
  export default SendsContainer;

