import axios from 'axios';
import URI from '../global/URI';
import CONST from '../global/CONST';

/** 본인인증 완료된 후 본인인증 결과와 서명이미지를 서버에 보내 저장. */
export function setApiResultAgreement(props, kmcData){
    const data = kmcData !== undefined ? JSON.parse(kmcData) : null;
    const url = URI.BASE_URL + URI.uriUpdateAgreement;
    const param = {
        tamID : props.value.tamID,
        tatID : props.value.tatID,
        urlSignImage : props.value.uriSignImage,
        signImageType : props.value.uriSignImage !== null ? CONST.SIGN_IMAGE_TYPE : null,
        certName : data !== null ? data.ownerNm : null ,
        certPhoneNumber : data !== null ? data.phoneNumber : null,
        certCI : data !== null ? data.kmcCI : null,
        certDI : data !== null ? data.kmcDI : null,     
    };
 
    post(url, param).then((result)=>{
        if (result.status === CONST.OK){
          props.props.history.replace('/complete');
        }else{
          alert("동의서 응답 업데이트에 실패하였습니다. 다시 시도해주세요.");
        }
    });
}

export function post(url, data) {
    return axios({
        method: 'post',
        url: url,
        data: data
    })
        .then((result) => {
            return result;
        })
        .catch((error) => alert("서버와의 통신이 원활하지 않습니다. 다시 시도해주세요."));
}

export function get(url) {
    return axios({
        method: 'get',
        url: url
    })
        .then((result) => {
            return result;
        })
        .catch((error) => alert("서버와의 통신이 원활하지 않습니다. 다시 시도해주세요."));
}
