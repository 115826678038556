import React, { Component, createContext } from 'react';

const Context = createContext({
                            ownerName : '',
                            phoneNo : '',
                            storeNm : '',
                            isPhoneValidate : false,
                      });
const { Provider, Consumer: LoginConsumer } = Context;

class LoginProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
        ownerName : '',
        phoneNo : '',
        storeNm : '',
        isPhoneValidate : false,

        isSelectStore : false,
        inputPhone : null, 
        inputStore : null, 
        inputOwner : null,
    };

    this.actions = {
      setValue: (key,value) => {
        this.setState({
          [key] : value
        });
      }
    }
   
  }

  render() {
    const { state, actions } = this;
    const value = { state, actions };
    return <Provider value={value}>{this.props.children}</Provider>;
  }
}

// 내보내줍니다.
export { LoginProvider, LoginConsumer };