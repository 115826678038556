import React from 'react';
import LoginForm from './LoginForm';
import { LoginConsumer } from '../../contexts/login';
import CONST from '../../global/CONST';
import './home.css';


const TartgetInfo = (TartgetInfo) => {

    /** 응답 버튼을 눌렀을 때 validate해주고 이상없으면 통과 */
    function onValidationInputData() {
        const storeNm = TartgetInfo.value.storeNm;
        const name = TartgetInfo.value.ownerName;
        const phoneNo = TartgetInfo.value.phoneNo;
        const isPhoneValidate = TartgetInfo.value.isPhoneValidate;

        /** focus를 위한 ref를 저장한 변수 */
        const inputPhone = TartgetInfo.value.inputPhone; 
        const inputStore = TartgetInfo.value.inputStore; 
        const inputOwner = TartgetInfo.value.inputOwner; 
        const isSelectStore = TartgetInfo.value.isSelectStore; 
        
        if(isSelectStore){
          if (storeNm.trim().length === CONST.ZERO){
              alert("매장명을 입력 후 선택해주세요.");
              inputStore.focus();
          }else if (name.trim().length === CONST.ZERO){
              alert("점주명을 입력 해주세요.");
              inputOwner.focus();
          }else if (!isPhoneValidate){
              alert("휴대폰번호를 입력해주세요.");
              inputPhone.focus();
          }else {
            TartgetInfo.props.onClickAnswerBtn(storeNm, name, phoneNo); //부모창에 있는 함수 호출
          }
        }else{
          alert("매장명을 입력 후 선택해주세요.");
          inputStore.focus();
        }

        
    }

    return (
        <div className="homeBgcLanding">
          <div className="lanchingDiv">
            <div>
                <p className="homeLandingTitle">{TartgetInfo.props.targetInfoData.tamNm}</p>
                <p className="landingDate">{TartgetInfo.props.targetInfoData.tamStartDT} ~ {TartgetInfo.props.targetInfoData.tamEndDT}</p>
                <p className="landingSummary">{(TartgetInfo.props.targetInfoData.tamSummaryDTL).split('<br>').map((line,idx)=>{
                  return (<span key={idx.toString()}>{line.replace(/&#39;/g, '\'')}<br/></span>);
                })}</p>
            </div>
            
            <div className="lineDiv"></div>

            <div>
                <LoginForm
                        targetItemList = {TartgetInfo.props.targetItemList}
                />
            </div>

            {/* <Link to='/sign' className="linkBtnStyle"> */}
              <div className="homeLandingBtn " onClick={onValidationInputData}>
                  <p className="homeBtnStyle">점주동의서 ></p>
              </div>
            {/* </Link> */}

          </div>
      </div>
    );

};

const SendsContainer = (props) => (
  <LoginConsumer>
        {
          ({state, actions}) => (
            <TartgetInfo 
                  props={props}
                  value={state}
                  setValue={actions.setValue}
            />
          )
        }
  </LoginConsumer>
)
export default SendsContainer;