import React  from 'react';
import { CertificationConsumer } from '../../../contexts/certification';
import '../answer.css';


const LableText = (LableText) => {
    function onChangeTextArea(event){
        LableText.setValue("subContent",event.target.value);
    }
 
    return (
        <div className="lableContainer">
                <p className="answerTitleTxt">{(LableText.data.text).split('<br>').map((line,idx)=>{
                  return (<span key={idx.toString()}>{line.replace(/&#39;/g, '\'')}<br/></span>);
                })}</p>
                <textarea 
                        onChange={onChangeTextArea}
                        style={{width:LableText.data.width*0.93, height:(LableText.data.height)*0.3,fontSize:16}} 
                        className="lableTextArea"
                        placeholder="텍스트 입력"
                        value={(LableText.value.subContent).replace(/(<br>|<br\/>|<br \/>)/g, '\r\n')}   //엔터값이 있는경우..
                />
        </div>
    );

};
const SendsContainer = (props) => (
    <CertificationConsumer>
          {
            ({state, actions}) => (
              <LableText
                    data={props}
                    value={state}
                    setValue={actions.setValue}
              />
            )
          }
    </CertificationConsumer>
  )
  export default SendsContainer;


