import React, { Component} from 'react'
import { CertificationConsumer } from '../../contexts/certification';
import Complete from '../../components/Complete/Complete'

class CompleteMain extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount = () => {
    this.props.setValue("tamID", '');
  }

  render() {
    return (
        <Complete/>
    );
  }
}
const SendsContainer = (props) => (
    <CertificationConsumer>
          {
            ({state, actions}) => (
              <CompleteMain 
                    props={props}
                    match={props.match}
                    value={state}
                    setValue={actions.setValue}
              />
            )
          }
    </CertificationConsumer>
)
export default SendsContainer;