
import React, {useState, useEffect} from 'react';
import { CertificationConsumer } from '../../contexts/certification';
import TextCheckBox from '../../components/Answer/Body/TextCheckbox';
import LableText from '../../components/Answer/Body/LableText';
import CONST from '../../global/CONST';
import './answer.css';



const AnswerItem = (AnswerItem) => {
    const [width, setWidth] = useState(CONST.ZERO);
    const [height, setHeight] = useState(CONST.ZERO);
    /** 텍스트박스 넓이 높이 조정 */
    function setTextArea(){
        setWidth((document.getElementById('bodyContainer').offsetWidth));
        setHeight((document.getElementById('bodyContainer').offsetHeight));
    }

    /** 체크박스  */
    function validationCheckbox(data){
        if(!data){
            alert("동의서 약관에 동의체크를 해주셔야 됩니다.");
        }else{
           AnswerItem.onClickNextPage(CONST.ONE);
        }

    }
 
    /** lable */
    function validationTextArea(data){
        if(data.trim()===''){
            alert("텍스트에 값을 입력해주셔야 됩니다.");
        }else{
            AnswerItem.onClickNextPage(data);
        }
    }

    function onClickNextBtn(){
        if(AnswerItem.data.tasKindCd ===  CONST.ANSWER_ITEM_TYPE.TEXT_TYPE){
            validationCheckbox(AnswerItem.value.subContent);
        }else{
            const textValue = AnswerItem.value.subContent;
            const value =  textValue.replace(/(?:\r\n|\r|\n)/g, '<br/>');
            validationTextArea(value);
        }
    }

    useEffect(() => {    
        window.addEventListener('resize', setTextArea);
        setTextArea();                // textarea 가로, 높이 세팅
        return () => window.removeEventListener('resize', setTextArea);
    },[]);
    
    return (
        <div className="bgcLanding">
            <div className="anwerContainer"  id="bodyContainer">
                <div className="titleContainer" >
                    <div className="answerTitle">
                        <p className="answerTitleTxt">{AnswerItem.data.tasItemTitle}</p>
                    </div>
                    <div className="answerTitleCount">
                        <p className="answerTitleTxt">{AnswerItem.currentPage} / {AnswerItem.totalPage}</p>
                    </div>
                </div>
                <hr width="100%"></hr>

                <div className="bodyContainer" >
                    {(AnswerItem.data.tasKindCd === CONST.ANSWER_ITEM_TYPE.TEXT_TYPE) ? 
                        // 텍스트 + 체크박스 (40210)
                        <TextCheckBox
                                text={AnswerItem.data.tasItemTxt}
                                width={width}
                                height={height}
                        />
                    :
                        // 레이블 + 텍스트박스 (40220)
                        <LableText
                                text = {AnswerItem.data.tasItemTxt}
                                width={width}
                                height={height}
                        />
                       
                    }
                    
                   
                </div>

                <div className="landingBtn ">
                    {AnswerItem.currentPage === CONST.ONE &&
                          <button className="btnStyle" onClick={onClickNextBtn} >다음 </button> 
                    }
                    {(AnswerItem.currentPage > CONST.ONE) &&
                    <div>
                        <button className="halfBtnStyle" onClick={AnswerItem.onClickPrevPage} >  이전 </button>
                       
                        <button className="halfBtnStyle" onClick={onClickNextBtn} >다음 </button>
                    </div>
                    }
                    
                </div>
            </div>
        </div>
    );

};

const SendsContainer = (props) => (
    <CertificationConsumer>
          {
            ({state, actions}) => (
              <AnswerItem
                    onClickPrevPage = {props.onClickPrevPage}
                    onClickNextPage = {props.onClickNextPage}
                    currentPage = {props.currentPage}
                    totalPage = {props.totalPage}
                    data = {props.data}
                    value={state}
                    setValue={actions.setValue}
              />
            )
          }
    </CertificationConsumer>
  )
  export default SendsContainer;

