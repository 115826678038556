import React, { Component, createContext } from 'react';

const Context = createContext({
                            tamID: '',
                            tatID : '',
                            uriSignImage : null,
                            tasID : ''
                      });
const { Provider, Consumer: CertificationConsumer } = Context;

class CertificationProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tamID: '',
      tatID : '',
      uriSignImage : null,    // 서명 사인 이미지경로
      tasID : '',
      subContent : '',      // 동의서 응답 답변변수
      tasKindCd : '',       // 동의서 응답 모드(텍스트냐, 체크박스냐...)
      isChangeData : false, // 화면전환을 위해 데이터 변동이 있냐 없냐의 변수
      isCertYn : '',        // 본인인증 여부
      isSignYn : '',        // 사인 여부
      
    };

    this.actions = {
      setValue: (key,value) => {
        this.setState({
          [key] : value
        });
      }
    }
   
  }

  render() {
    const { state, actions } = this;
    const value = { state, actions };
    return <Provider value={value}>{this.props.children}</Provider>;
  }
}

// 내보내줍니다.
export { CertificationProvider, CertificationConsumer };