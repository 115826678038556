import React, { Component } from 'react';
import { CertificationConsumer } from '../../contexts/certification';
import {  setApiResultAgreement } from '../../global/FUNC';
import SignatureCanvas from 'react-signature-canvas';
import Certification from '../Certification';
import CONST from '../../global/CONST';
import './sign.css';

class Sign extends Component {
  constructor(props) {
    super(props);

    this.state = {
        urlSignImage : '',
        signPadHeight : 0,     //sign-canvas width
        signPadWidth : 0,      //sign-canvas height

        isClickSendSign : false,
    }
  }
 
  onSign = async() => {
    if (this.sigCanvas.toData().length === CONST.ZERO){
      alert("서명란에 서명하신 후 제출 버튼을 눌러주세요.");
    }else{
      await this.props.setValue('uriSignImage' , this.sigCanvas.getTrimmedCanvas().toDataURL(CONST.SIGN_IMAGE_TYPE));

      if (this.props.value.isCertYn === CONST.YN_Y){
        this.setState({
          isClickSendSign : true,
        });
      }else{
        setApiResultAgreement(this.props);
      }
      
    }
    
  }

  componentDidMount = () => {
    if (this.props.value.tamID === ''){
        alert("올바르지 않은 접근입니다.");
        this.props.props.history.goBack();
        return;
    }

    this.setState({
      signPadHeight : this.sigDiv.offsetHeight,
      signPadWidth : this.sigDiv.offsetWidth
    });
  }

  render() {
    return (
      <div className="bgcLanding">
          <div className="signContainer">
              <p className="landingTitle">서명</p>

              <div className="signDiv" ref={(ref) => { this.sigDiv = ref }}>
                    <SignatureCanvas 
                                    ref={(ref) => { this.sigCanvas = ref }}
                                    penColor='black'
                                    canvasProps={{width:this.state.signPadWidth,height:this.state.signPadHeight}} 
                    />
              </div>

              <div className="landingBtn ">
                  <button className="btnStyle" onClick={()=>this.onSign()}>서명제출</button>
              </div>
          </div>

          {/* 사인제출 버튼을 눌렀을 때 본인인증 팝업 페이지 나올게.. */}
          {this.state.isClickSendSign &&
            <Certification
                        props={this.props.props}
            />
          }
         
      </div>
    );
  }
}
const SendsContainer = (props) => (
  <CertificationConsumer>
        {
          ({state, actions}) => (
            <Sign 
                  props={props}
                  value={state}
                  setValue={actions.setValue}
            />
          )
        }
  </CertificationConsumer>
)
export default SendsContainer;