
import React, { Component} from 'react'
import { CertificationConsumer } from '../../contexts/certification';
import URI from '../../global/URI';
import CONST from '../../global/CONST';
import {post, get} from '../../global/FUNC';
import TargetInfo from './TargetInfo';
import './home.css';

const ETC_STATUS = 'etc';                         // 동의서 진행 상태가 진행,준비,마감 외에 상태일때. null이나 예외 상황일경우
const ING_AGREEMENT = 'ing';                      // 동의서 응답 진행가능
const END_AGREEMENT = 'end';                      // 동의서 응답 기간 지남
const BEFORE_AGREEMEMT = 'before';                // 동의서 응답 시작 전
const IS_EXIST_AGREEMENT = 'IsExistAgreement';    // 동의서 존재 여부 

const END_TXT = "동의서 응답 가능한 기간이 지났습니다.";
const BEFORE_TXT = "동의서 준비중입니다.";
const IS_EXIST_AGREEMENT_TXT= "존재하지 않은 동의서 입니다.";
const ETC_STATUS_TXT="동의서 상태가 올바르지 않습니다. 관리자에게 문의해주세요.";

class Home extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        isLoading : true,
        tam_id : '',             // 동의서 메인 id
        tat_id : '',             // 동의서 타겟 id

        targetInfoData : null,     // 동의서 타겟 정보
        agreementStatus : ING_AGREEMENT,      // 해당동의서 타입(종료된 동의서인지, 응답된 동의서인지, 준비중인 동의서인지..)
        agreementText : '',

        targetItemList : '',          // 동의서 전체 타겟 리스트
    }
  
  }
  /** validation 완료 후 api를 통해 일치여부 확인 */
  onClickAnswerBtn = (storeNm, ownerNm, phoneNo) => {
    const url = URI.BASE_URL + URI.uriGetTargetId +
                "?tamId=" + this.state.tam_id+
                "&storeNm=" + storeNm+ 
                "&ownerNm=" + ownerNm + 
                "&phoneNo=" + phoneNo; 

    post(url, null).then((result)=>{
      if (result.status === CONST.OK){
        if(result.data.targetInfoVO !== undefined){  
          const answerYN = result.data.targetInfoVO.taamAnswerYn;
          if(answerYN === CONST.YN_Y){
            alert("이미 해당동의서에 응답하셨습니다.");
          }else{
            const tatId = result.data.targetInfoVO.tatId;
            this.setAPIAgreementViewUpdate(tatId);
          }
         
        }else{
          alert("입력하신 정보가 올바르지 않습니다. 확인 후 다시 시도해주세요.")
        }

      }else{
        alert("로그인에 실패하였습니다. 다시 시도해주세요.");
      }
    });

  }
  /** 점주동의서 확인 버튼을 눌렀을 때 api를 통해 수신일시와 수신했단느 정보 업데이트 */
  setAPIAgreementViewUpdate = (tatId) => {
    this.props.setValue("tamID", this.state.tam_id);
    this.props.setValue("tatID", tatId);
  
    const url = URI.BASE_URL + URI.uriUpdateViewStatus +
                "?TAM_ID="+this.state.tam_id+
                "&TAT_ID="+tatId;

    post(url, null).then((result)=>{
        if (result.status === CONST.OK){
          this.props.setValue("tasID", CONST.ONE);
          this.props.props.history.push('/answer');
        }else{
          alert("동의서 확인 업데이트에 실패하였습니다. 다시 시도해주세요.");
        }
    });
  }


  /** 동의서 상태(응답여부, 동의서 응답 일자 전/후 에 따라 알맞은 컴포넌트 보여주기) */
  onCheckAgreementStatus = () => {
      const status = this.state.targetInfoData.tamStatusCd;
    
      if (status === CONST.AGREEMENT_TYPE.READY){
        /** 준비 */
        this.setState({
          agreementStatus : BEFORE_AGREEMEMT, 
          agreementText : BEFORE_TXT,
          isLoading : false
        });
      } else if(status === CONST.AGREEMENT_TYPE.ING){
        /** 진행 */
        this.setState({
          agreementStatus : ING_AGREEMENT, 
          isLoading : false
        });
      } else if(status === CONST.AGREEMENT_TYPE.END){
        /** 마감 */
        this.setState({
          agreementStatus : END_AGREEMENT, 
          agreementText : END_TXT,
          isLoading : false
        });
      } else{
        /** 기타 예외 상태 */
        this.setState({
          agreementStatus : ETC_STATUS, 
          agreementText : ETC_STATUS_TXT,
          isLoading : false
        });
        
      }
  }

  /** 해당동의서에 응답 가능한 매장명들을 가져오는 함수 */
  getTargetNameList = () => {
    const url = URI.BASE_URL + URI.uriGetTargetList +
               "?tamId=" + this.state.tam_id;

    get(url).then((result)=>{
        if(result.data.targetItemList.length > CONST.ZERO){
        this.setState({
            targetItemList : result.data.targetItemList
        },()=>this.onCheckAgreementStatus())
    }
    });
  }
  
  /** api에서 tam_id를 통해 동의서정보를 가져온다.  */
  getApiAgreementTargetInfo = () => {
    const url = URI.BASE_URL + URI.uriGetTargetInfo+
                "?TAM_ID="+this.state.tam_id;

    get(url, null).then((result)=>{
        if (result === undefined){
          this.props.props.history.replace("/");
        }else{
            if (result.status === CONST.OK){

                if(result.data.targetInfoVO !== undefined){
                  /** 해당 동의서 정보가 있을 경우 */
                  this.props.setValue("isCertYn", result.data.targetInfoVO.tamCertYn);
                  this.props.setValue("isSignYn", result.data.targetInfoVO.tamSignYn);

                  this.setState({
                    targetInfoData : result.data.targetInfoVO 
                  },()=>this.getTargetNameList());
                }else{
                  /** 해당 동의서 정보가 없을 경우 */
                  this.setState({
                    agreementStatus : IS_EXIST_AGREEMENT, 
                    agreementText : IS_EXIST_AGREEMENT_TXT,
                    isLoading : false
                  });
                }
            }else{
              alert("동의서 타겟정보가 올바르지 않습니다. 다시 시도해주세요.");
            }
        }

       
    });
  }

  componentDidMount = () => {
    /** url을 통해 넘어오는 데이터를 가지고 api로 데이터를 보내 점주 정보를 가져온다. */
    if(this.props.match.path !== '/'){
      this.props.setValue("isChangeData",false);       // 다음페이지인 해당 동의서응답 페이지에서 초기값을 설정(데이터가 변하지 않았다는 뜻..)
      const id = this.props.match.params;
      this.setState({
        tam_id : id.TAM_ID,
      },()=>{
        this.getApiAgreementTargetInfo()
      });
      
    }
    
  }

  render() {
    return (
      <div className="textBgcLanding">
          {!this.state.isLoading &&
              <div>
                  {this.state.agreementStatus === ING_AGREEMENT ?
                      /** 진행중 */
                      <TargetInfo
                              onClickAnswerBtn = {this.onClickAnswerBtn}
                              targetItemList = {this.state.targetItemList}
                              targetInfoData= {this.state.targetInfoData}
                      />
                    :
                      /* 진행중인 상태를 제외하고 보여주는 부분 */
                      <div className="lanchingDiv" style={{width:'auto'}}>
                        <p className="textLandingTitle">{this.state.agreementText}</p>
                      </div>
                  }
              </div>        
          }
      </div>
    );
  }
}
const SendsContainer = (props) => (
      <CertificationConsumer>
            {
              ({state, actions}) => (
                <Home 
                      props={props}
                      match={props.match}
                      value={state}
                      setValue={actions.setValue}
                />
              )
            }
      </CertificationConsumer>
)
export default SendsContainer;