import React from 'react';
import { Route } from 'react-router-dom';
import Home from './screen/Home';
import Certificaiton from './screen/Certification';
import Sign from './screen/Sign';
import Answer from './screen/Answer';
import Complete from './screen/Complete';
import {CertificationProvider} from './contexts/certification';
import {LoginProvider} from './contexts/login';

function App() {
  return (
      <CertificationProvider>
          <Route exact path="/" component={Home}/>
          <LoginProvider>
             <Route exact path="/home/:TAM_ID" component={Home}/>
          </LoginProvider>
          <Route path="/answer" component={Answer}/>
          <Route path="/cert" component={Certificaiton}/>
          <Route path="/sign" component={Sign}/>
          <Route path="/complete" component={Complete}/>
      </CertificationProvider>
  );
}

export default App;
