
  /* 상수 저장용 */
  module.exports = {
  
    SIGN_IMAGE_TYPE : 'image/png',    //sing-pad 사인시 저장될 이미지 타입
    SUB_TYPE : {
      CHECK : 'checkbox',
      TEXT : 'textbox'
    },

    /** 동의서 상태 코드 */
    AGREEMENT_TYPE : {
        READY : '40110',    //준비
        ING : '40120',      //진행
        END : '40190'      //마감
    },

    /** 동의서sub 응답 모드 */
    ANSWER_ITEM_TYPE : {
        TEXT_TYPE : '40210',   //텍스트+체크박스
        LABLE_TYPE : '40220',  //레이블+텍스트박스
    },

    YN_Y : 'Y',
    YN_N : 'N',
    
    ZERO : 0,
    ONE : 1,
    OK: 200,
    CREATED : '201',
    ERROR: '400',
    DENIED_CODE : '403',
    UNAUTHORIZED :  '401',
    HAVE_NO_DATA: '99999',
    CANCEL : 'CANCEL',
    NETWORK_ERROR : 'Error: Network Error',
  
  
  
  }