import React from 'react';
import './complete.css';

const Complete = () => {

  

    return (
        <div className="completeBgcLanding">
            <p className="completeLandingTitle">동의서 응답이 완료되었습니다.</p>
        </div>
    );

};

export default Complete;

