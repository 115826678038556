
module.exports = {

  // BASE_URL : 'http://localhost:8080/tollgate',
  // BASE_URL : 'https://tgdev.tollgateapp.com',
  BASE_URL : 'https://tg.tollgateapp.com',
  uriCert : '/v1/agreement/answer/cert.do',
  uriKmc : '/kmcis/web/kmcisReq.jsp',
  uriGetTargetInfo : '/v1/agreement/answer/getTargetInfo.do',
  uriUpdateViewStatus :  '/v1/agreement/answer/updateViewStatus.do', 
  uriUpdateAgreement : '/v1/agreement/answer/updateAgreement.do',
  uriGetAgreementItemList : '/v1/agreement/answer/getAgreementItemList.do',
  uriSetAgreementAnswer : '/v1/agreement/answer/setAgreementSubAnswer.do',
  uriGetAgreementAnswerItem : '/v1/agreement/answer/getAgreementAnswerItem.do',
  uriGetTargetList : '/v1/agreement/answer/getTargetList.do',
  uriGetTargetId : '/v1/agreement/answer/getTargetId.do' 

}
