import React, { Component } from 'react';
import { CertificationConsumer } from '../../contexts/certification';
import { get, setApiResultAgreement } from '../../global/FUNC';
import Certification from '../Certification';
import CONST from '../../global/CONST';
import URI from '../../global/URI';
import AnswerItem from './AnswerItem';

class Answer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answerItem: '',
            answerItemList: '',             // 동의서 응답 데이터
            currentPage: CONST.ONE,        // 동의서 응답 현재 페이지,
            totalCount: CONST.ZERO,

            tasId : CONST.ONE,
            isClickSendSign : false,       // 본인인증 클릭 여부

        }
    }

    /** 마지막 페이지 인지 아닌지   */
    onCheckLastPage = () => {
        if (this.state.tasId > this.state.totalCount) {
            /** 마지막 페이지 */
            if (this.props.value.isSignYn === CONST.YN_Y){
                this.props.props.history.replace('/sign');
            }else {
                if (this.props.value.isCertYn === CONST.YN_Y){
                    this.setState({isClickSendSign : true});
                }else {
                    setApiResultAgreement(this.props);
                }    
            }         
        } else {
            this.props.setValue("isChangeData", false);                 //데이터 변동시작 전이라 false로 수정
            this.getAgreementAnswerInfo();
        }
    }

    /* 해당 동의서 응답 저장  */
    setApiAgreementAnswer = (data) => {
        const url = URI.BASE_URL + URI.uriSetAgreementAnswer +
                    "?tamId=" + this.props.value.tamID +
                    "&tatId=" + this.props.value.tatID +
                    "&tasId=" + this.state.tasId +
                    "&itemTxt=" + data;

        get(url).then(async(result) => {
            if (result.status === CONST.OK) {
                this.setState({
                    tasId : Number(this.state.tasId) + CONST.ONE 
                },()=> {
                    this.onCheckLastPage();
                });
                
            } else {
                alert("동의서 응답 저장에 실패하였습니다. 다시시도해주세요");
            }
        });
    }

    /* 다음 버튼 눌렀을 때 */
    goNextAnswerPage = (data) => {
         this.setApiAgreementAnswer(data);
    }

    /* 이전버튼 눌렀을 때 */
    goPrevAnswerPage = () => {
        this.props.setValue("isChangeData", false);
        this.setState({tasId : Number(this.state.tasId) - CONST.ONE });
        this.setState({
            currentPage: this.state.currentPage - 1
        }, () => {
            this.getAgreementAnswerInfo();
        });
    }

    /* 동의서 응답 정보 및 답변 정보 가져오기*/
    getAgreementAnswerInfo = async () => {

        const url = URI.BASE_URL + URI.uriGetAgreementAnswerItem +
                    "?tamId=" + this.props.value.tamID +
                    "&tasId=" + this.state.tasId +
                    "&tatId=" + this.props.value.tatID;
                    
        get(url).then((result) => {
            console.log(result);
            /** 데이터가 없을 시에는 기본값으로 유지되면 되기 때문에 else는 따로 추가하지 않았음 */
            if (result.status === CONST.OK) {
                if (result.data.answerItemVO.tasKindCd === CONST.ANSWER_ITEM_TYPE.TEXT_TYPE) {
                    this.props.setValue("subContent", result.data.answerItemVO.taasItemTxt === null ? false : true);
                } else {
                    this.props.setValue("subContent", result.data.answerItemVO.taasItemTxt === null ? "" : result.data.answerItemVO.taasItemTxt);
                }

                this.props.setValue("tasKindCd", result.data.answerItemVO.tasKindCd);

                this.setState({
                    answerItem: result.data.answerItemVO
                }, () => this.props.setValue("isChangeData", true));
            }
        });
    }

    componentDidMount = async () => {

        if (this.props.value.tamID === ''){
            alert("올바르지 않은 접근입니다.");
            this.props.props.history.goBack();
            return;
        }
        const url = URI.BASE_URL + URI.uriGetAgreementItemList +
                    "?tamId=" + this.props.value.tamID;
       
        get(url).then((result) => {
            if (result.status === CONST.OK) {
                if (result.data.answerItemVOList.length > CONST.ZERO) {
                    this.setState({
                        totalCount: result.data.answerItemVOList.length
                    }, async () => await this.getAgreementAnswerInfo());
                }
            } else {
                alert("동의서 아이템 가져오는데 실패하였습니다. 다시시도해주세요");
            }
        });
    }

    render() {
        return (
            <div className="bgcLanding">
                {/* 데이터 변동이 완료되었을 때만 랜더링 되게.. */}
                {this.props.value.isChangeData &&
                    <AnswerItem
                        onClickPrevPage={this.goPrevAnswerPage}
                        onClickNextPage={this.goNextAnswerPage}
                        currentPage={this.state.tasId}
                        totalPage={this.state.totalCount}
                        data={this.state.answerItem}
                    />
                }

                {/* 사인제출 버튼을 눌렀을 때 본인인증 팝업 페이지 나올게.. */}
                {this.state.isClickSendSign &&
                    <Certification
                                props={this.props.props}
                    />
                }
            </div>
        );
    }
}
const SendsContainer = (props) => (
    <CertificationConsumer>
        {
            ({ state, actions }) => (
                <Answer
                    props={props}
                    match={props.match}
                    value={state}
                    setValue={actions.setValue}
                />
            )
        }
    </CertificationConsumer>
)
export default SendsContainer;